import { USER_ID } from "constants/AuthConstant";
import { io } from "socket.io-client";

const URL = process.env.REACT_APP_SOCKET_ENDPOINT_URL;
const userId = localStorage.getItem(USER_ID);
export const socket = io(`${URL}/orders`, {
  autoConnect: false,
  query: {
    user_id: userId,
  },
});

export const socketCalls = io(`${URL}/calls`, {
  autoConnect: true,
  query: {
    user_id: userId,
  },
});
