import { createContextualCan } from "@casl/react";
import { useAbilities } from "hooks/useAbilities";
import { createContext, memo } from "react";

const AbilityContext = createContext();
const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider = memo(({ children }) => {
  const { abilities } = useAbilities();
  
  return (
    <AbilityContext.Provider value={abilities}>
      {children}
    </AbilityContext.Provider>
  );
});

export { AbilityContext, AbilityProvider, Can };
