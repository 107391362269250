import { PureAbility } from "@casl/ability";
import { useState, useEffect, useCallback } from "react";
import { useAuth } from "stores/auth.store";

export function useAbilities() {
  const [abilities, setAbilities] = useState(new PureAbility());
  const { user } = useAuth();

  const updateAbilities = useCallback(
    (rules) => {
      const newAbilities = new PureAbility(rules);
      setAbilities(newAbilities);
    },
    [setAbilities]
  );

  useEffect(() => {
    if (user) {
      updateAbilities(user.rules);
    }
  }, [user, updateAbilities]);

  return { abilities, updateAbilities };
}
