import { useAbility } from "@casl/react";
import { Grid, Menu, Typography } from "antd";
import navigationConfig from "configs/NavigationConfig";
import {
  FeatureCodeAction,
  RoleFeatureCode,
} from "constants/CodeFeaturesConstant";
import { NAV_TYPE_SIDE, SIDE_NAV_LIGHT } from "constants/ThemeConstant";
import { AbilityContext, Can } from "contexts/ability";
import { Link } from "react-router-dom";
import { useTheme } from "stores/theme.store";
import utils from "utils";
import Icon from "../util-components/Icon";
import IntlMessage from "../util-components/IntlMessage";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;
const { VIEW } = FeatureCodeAction;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    navCollapsed,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const ability = useAbility(AbilityContext);
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };

  const hasNursingGroupTitle =
    ability.can(VIEW, RoleFeatureCode.MONITOR_EVENTS) ||
    ability.can(VIEW, RoleFeatureCode.DASHBOARD_CALLS) ||
    ability.can(VIEW, RoleFeatureCode.REPORTS);

  const hasRequestsGroupTitle =
    ability.can(VIEW, RoleFeatureCode.MONITOR_EVENTS) ||
    ability.can(VIEW, RoleFeatureCode.DASHBOARD_REQUESTS) ||
    ability.can(VIEW, RoleFeatureCode.ITEMS_AVAILABLE);

  const hasUsersGroupTitle =
    ability.can(VIEW, RoleFeatureCode.SECTORS_AND_ROOMS) ||
    ability.can(VIEW, RoleFeatureCode.USERS);

  const hasGroupTitle = (menu) => {
    if (menu === "titleNursing") {
      return hasNursingGroupTitle;
    } else if (menu === "titleRequests") {
      return hasRequestsGroupTitle;
    } else if (menu === "titleUsers") {
      return hasUsersGroupTitle;
    }
    return false;
  };

  return (
    <>
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {navigationConfig.map((menu) => {
          const hasGroup = hasGroupTitle(menu.key);
          return hasGroup && menu.submenu.length > 0 ? (
            menu?.title ? (
              <Menu.ItemGroup
                key={menu.key}
                title={setLocale(localization, menu.title)}
              >
                {menu.submenu.map((subMenuFirst) =>
                  subMenuFirst.submenu.length > 0 ? (
                    <SubMenu
                      icon={
                        subMenuFirst.icon ? (
                          <Icon type={subMenuFirst?.icon} />
                        ) : null
                      }
                      key={subMenuFirst.key}
                      title={setLocale(localization, subMenuFirst.title)}
                    >
                      {subMenuFirst.submenu.map((subMenuSecond) => (
                        <Menu.Item key={subMenuSecond.key}>
                          {subMenuSecond.icon ? (
                            <Icon type={subMenuSecond?.icon} />
                          ) : null}
                          <span>
                            {setLocale(localization, subMenuSecond.title)}
                          </span>
                          <Link
                            onClick={() => closeMobileNav()}
                            to={subMenuSecond.path}
                          />
                        </Menu.Item>
                      ))}
                    </SubMenu>
                  ) : (
                    <Menu.Item
                      key={subMenuFirst.key}
                      style={{
                        display: ability.can(VIEW, subMenuFirst.key)
                          ? "block"
                          : "none",
                        paddingLeft: "24px",
                      }}
                    >
                      <Can I={VIEW} this={subMenuFirst.key}>
                        {subMenuFirst.icon ? (
                          <Icon type={subMenuFirst.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuFirst.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuFirst.path}
                        />
                      </Can>
                    </Menu.Item>
                  )
                )}
              </Menu.ItemGroup>
            ) : null
          ) : (
            <Menu.Item
              key={menu.key}
              style={{
                display: ability.can(VIEW, menu.key) ? "block" : "none",
              }}
            >
              <Can I={VIEW} this={menu.key}>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu?.title)}</span>
                {menu.path ? (
                  <Link onClick={() => closeMobileNav()} to={menu.path} />
                ) : null}
              </Can>
            </Menu.Item>
          );
        })}
      </Menu>
      {!navCollapsed && (
        <Typography
          style={{
            position: "absolute",
            bottom: "5px",
            paddingLeft: "24px",
            paddingBottom: "10px",
            color: "#1a335399",
            textAlign: "initial",
          }}
        >
          {`Versão: ${process.env.REACT_APP_VERSION || "1.0.0"}`}
        </Typography>
      )}
    </>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  const { sideNavTheme, topNavColor, onMobileNavToggle } = useTheme();
  const p = { ...props, sideNavTheme, topNavColor, onMobileNavToggle };
  return p.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...p} />
  ) : (
    <TopNavContent {...p} />
  );
};

export default MenuContent;
