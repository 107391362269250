import { notification } from "antd";
import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import { AUTH_TOKEN, REFRESH_TOKEN } from "constants/AuthConstant";
import history from "../history";
import { useAuth } from "stores/auth.store";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    notification.error({
      message: "Error",
    });
    return Promise.reject(error);
  }
);

// API Response interceptor
service.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = localStorage.getItem(REFRESH_TOKEN);

      if (refreshToken) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + refreshToken;
        try {
          const response = await axios.post(`${API_BASE_URL}/employee/refresh`);
          if (response) {
            localStorage.setItem(AUTH_TOKEN, response.data.access_token);
            localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token);

            return service(originalRequest);
          }
        } catch (refreshError) {
          useAuth.getState().logout();
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default service;
