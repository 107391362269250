import {
  AlertOutlined,
  ApartmentOutlined,
  BarChartOutlined,
  BorderInnerOutlined,
  FileSearchOutlined,
  GroupOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { RoleFeatureCode } from "constants/CodeFeaturesConstant";

const dashBoardNavTree = [
  {
    key: "titleNursing",
    path: "",
    title: "nursing",
    breadcrumb: false,
    submenu: [
      {
        key: RoleFeatureCode.MONITOR_EVENTS,
        path: `${APP_PREFIX_PATH}/monitor-de-eventos`,
        title: "eventMonitor",
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.DASHBOARD_CALLS,
        path: `${APP_PREFIX_PATH}/dashboard/chamados`,
        title: "dashboardCalls",
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.REPORTS,
        path: `${APP_PREFIX_PATH}/relatorios`,
        title: "reports",
        icon: FileSearchOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: "titleRequests",
    path: "",
    title: "requests",
    breadcrumb: false,
    submenu: [
      {
        key: RoleFeatureCode.MONITOR_EVENTS,
        path: `${APP_PREFIX_PATH}/monitor-de-eventos`,
        title: "eventMonitor",
        icon: AlertOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.DASHBOARD_REQUESTS,
        path: `${APP_PREFIX_PATH}/dashboard/solicitacoes`,
        title: "dashboardRequests",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.ITEMS_AVAILABLE,
        path: `${APP_PREFIX_PATH}/itens-disponiveis`,
        title: "availableItems",
        icon: UnorderedListOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },

  {
    key: "titleUsers",
    path: "",
    title: "management",
    breadcrumb: false,
    submenu: [
      {
        key: RoleFeatureCode.GROUPS,
        path: `${APP_PREFIX_PATH}/grupos`,
        title: "groups",
        icon: GroupOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.SECTORS_AND_ROOMS,
        path: `${APP_PREFIX_PATH}/setores-e-quartos`,
        title: "sectorsAndRooms",
        icon: BorderInnerOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.USERS,
        path: `${APP_PREFIX_PATH}/usuarios`,
        title: "users",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: RoleFeatureCode.ROLES_AND_PERMISSIONS,
        path: `${APP_PREFIX_PATH}/cargos-e-permissoes`,
        title: "rolesAndPermissions",
        icon: ApartmentOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
