export const RoleFeatureCode = {
  DASHBOARD_REQUESTS: "F0000",
  DASHBOARD_CALLS: "F0001",
  REPORTS: "F0002",
  ITEMS_AVAILABLE: "F0003",
  SECTORS_AND_ROOMS: "F0004",
  USERS: "F0005",
  ROLES_AND_PERMISSIONS: "F0006",
  GROUPS: "F0007",
  CALLS: "F0008",
  REQUESTS: "F0009",
  OTHER_REQUESTS: "F0010",
  MONITOR_EVENTS: "F0011",
  CREATE_ORDER: "F0012",
};

export const FeatureCodeAction = {
  VIEW: "view",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  ACCEPT: "accept",
  ON_THE_WAY: "on_the_way",
};

export const SCREENS = [
  {
    code: RoleFeatureCode.ITEMS_AVAILABLE,
    name: "Itens disponíveis",
  },
  {
    code: RoleFeatureCode.SECTORS_AND_ROOMS,
    name: "Setores e quartos",
  },
  {
    code: RoleFeatureCode.USERS,
    name: "Usuários",
  },
  {
    code: RoleFeatureCode.ROLES_AND_PERMISSIONS,
    name: "Cargos e permissões",
  },
  {
    code: RoleFeatureCode.GROUPS,
    name: "Grupos",
  },
  {
    code: RoleFeatureCode.DASHBOARD_REQUESTS,
    name: "Dashboard de solicitações",
    onlyView: true,
  },
  {
    code: RoleFeatureCode.DASHBOARD_CALLS,
    name: "Dashboard de chamados",
    onlyView: true,
  },
  {
    code: RoleFeatureCode.REPORTS,
    name: "Relatórios",
    onlyView: true,
  },
];
