import fetch from "auth/FetchInterceptor";

const login = async (data) => {
  const result = await fetch({
    url: `/employee/login`,
    method: "post",
    headers: {
      "public-request": "true",
    },
    data,
  });
  return result.data;
};

const refreshToken = async () => {
  const result = await fetch({
    url: "/employee/refresh",
    method: "post",
  });
  return result.data;
};

const logout = async () => {
  const result = await fetch({
    url: "/employee/logout",
    method: "post",
  });
  return result.data;
};

const authService = {
  login,
  refreshToken,
  logout,
};

export default authService;
