import authService from "services/auth.service";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import {
  AUTH_STORAGE,
  AUTH_TOKEN,
  REFRESH_TOKEN,
  USER,
  USER_ID,
} from "../constants/AuthConstant";
import {
  FeatureCodeAction,
  RoleFeatureCode,
} from "constants/CodeFeaturesConstant";

export const useAuth = create(
  persist(
    (set, get) => ({
      loading: false,
      message: "",
      showMessage: false,
      userId: localStorage.getItem(USER_ID),
      redirect: "/",
      token: localStorage.getItem(AUTH_TOKEN),
      refreshToken: localStorage.getItem(REFRESH_TOKEN),
      user: localStorage.getItem(USER),
      socketConnection: false,

      showAuthMessage: (message) => {
        set({ message, showMessage: true, loading: false });
      },
      showLoading: () => {
        set({ loading: true });
      },
      hideAuthMessage: () => {
        set({ message: "", showMessage: false });
      },
      authenticated: (token) => {
        set({
          loading: false,
          redirect: "/app/monitor-de-eventos",
          token: token,
        });
      },

      login: async (body) => {
        set({ loading: true, showMessage: false });
        try {
          const result = await authService.login(body);

          let rules = [...result.ocupation.rules];

          if (result.ocupation.admin_ocupation) {
            rules = [
              ...rules,
              {
                subject: RoleFeatureCode.CALLS,
                action: FeatureCodeAction.VIEW,
              },
              {
                subject: RoleFeatureCode.OTHER_REQUESTS,
                action: FeatureCodeAction.VIEW,
              },
              {
                subject: RoleFeatureCode.CREATE_ORDER,
                action: FeatureCodeAction.VIEW,
              },
            ];
          } else {
            if (result.ocupation.receive_requests) {
              rules.push({
                subject: RoleFeatureCode.OTHER_REQUESTS,
                action: FeatureCodeAction.VIEW,
              });
            }
            if (result.ocupation.create_order) {
              rules.push({
                subject: RoleFeatureCode.CREATE_ORDER,
                action: FeatureCodeAction.VIEW,
              });
            }

            result.groups.forEach((group) => {
              if (group.type === "CALLS") {
                rules.push({
                  subject: RoleFeatureCode.CALLS,
                  action: FeatureCodeAction.VIEW,
                });
              } else if (group.type === "ORDERS") {
                rules.push({
                  subject: RoleFeatureCode.REQUESTS,
                  action: FeatureCodeAction.VIEW,
                });
              }
            });
          }

          const hasRequiredRules = rules.some((rule) =>
            [
              RoleFeatureCode.CALLS,
              RoleFeatureCode.REQUESTS,
              RoleFeatureCode.OTHER_REQUESTS,
            ].includes(rule.subject)
          );

          const rulesUpdate = hasRequiredRules
            ? [
                ...rules,
                {
                  subject: RoleFeatureCode.MONITOR_EVENTS,
                  action: FeatureCodeAction.VIEW,
                },
              ]
            : rules;

          set({
            token: result.access_token,
            refreshToken: result.refresh_token,
            userId: result.userUuid,
            user: {
              name: result.name,
              email: result.email,
              employeeRole: result.ocupation.name,
              employeeId: result.ocupation.id,
              sectorId: result.sector_id,
              ocupationId: result.ocupation.id,
              isAdmin: result.ocupation.admin_ocupation,
              groups: result.groups,
              rules: rulesUpdate,
            },
          });
          window.localStorage.setItem(AUTH_TOKEN, result.access_token);
          window.localStorage.setItem(REFRESH_TOKEN, result.refresh_token);
          window.localStorage.setItem(USER_ID, result.id);
          window.localStorage.setItem(USER, {
            name: result.name,
            email: result.email,
            employeeRole: result.ocupation.name,
            sectorId: result.sector_id,
            ocupationId: result.ocupation.id,
            isAdmin: result.ocupation.admin_ocupation,
            groups: result.groups,
          });
          window.location.href = "/app/monitor-de-eventos";

          return result;
        } catch (e) {
          if (e?.response?.status === 500) {
            set({
              message: "Ocorreu um erro. Contate um administrador.",
              showMessage: true,
            });
          } else {
            set({
              message: e?.response?.data?.message,
              showMessage: true,
            });
          }
        } finally {
          set({
            loading: false,
          });
        }
      },
      logout: async () => {
        try {
          set({ token: null, userId: null, user: null, refreshToken: null });
          await authService.logout();
          localStorage.removeItem(AUTH_TOKEN);
          localStorage.removeItem(USER_ID);
          localStorage.removeItem(USER);

          window.location.href = "/auth/login";
        } catch (e) {
          console.log(e);
        }
      },
      setSocketConnection: (status) => {
        set({ socketConnection: status });
      },
    }),
    {
      name: AUTH_STORAGE,
    }
  )
);
